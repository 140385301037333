import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PermissionUsageService {
  apiURL = environment.apiURL;
  constructor(private http: HttpClient) { }

  getUsageCounts(idPublicationGroup, usageType, accessType){
    return this.http.post(this.apiURL+'permission-usages/usagecounts', {idPublicationGroup: idPublicationGroup, usageType: usageType, accessType: accessType});
  }

  addUsageHistory(data){
    return this.http.post(this.apiURL+'permission-usages', data);
  }
}
