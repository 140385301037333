export const environment = {
  production: true,
  versionURL: "?t=" + new Date().getTime(),
  // apiURL: "https://api.iwrapper.com/v1/",
  // baseapiURL: "https://api.iwrapper.com/",
  storageURL: "https://iwrapperstoragestandard.blob.core.windows.net",
  apiURL: "https://iwrapper-api.azurewebsites.net/v1/",
  baseapiURL: "https://iwrapper-api.azurewebsites.net/",

  fileReferrer: document.referrer,
  captchaSiteKey: "6Lc1pPIbAAAAANzXWxC3N7YOFfCHfRkEVUNv6n3L",
  captchaSecretKey: "6Lc1pPIbAAAAABn1nIAFD96WAPG0cSKt8nDzA2Lf",
};
