import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class PaymentService {
  apiURL = environment.apiURL;
  constructor(private http: HttpClient) {}

  postOrderDetail(orderDetail) {
    return this.http.post(this.apiURL + "order", { orderDetail: orderDetail });
  }

  validateCoupon(coupon, publicationId) {
    return this.http.post(this.apiURL + "promotions/verifyCoupon", {
      code: coupon,
      publicationId: publicationId,
    });
  }

  downloadPurchasedPublication(orderNo) {
    return this.http.post(this.apiURL + "order/downloadpublication", orderNo);
  }

  validateOrderNo(orderNo) {
    return this.http.post(this.apiURL + "order/validateOrder", orderNo);
  }

  downloadPurchasedPublicationByUrl(orderNo) {
    return this.http.post(
      this.apiURL + "order/downloadpublicationByUrl",
      orderNo
    );
  }
}
