import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable, Subject, BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AdService {

  private adShowSource = new BehaviorSubject<boolean>(true);
  adShow$ = this.adShowSource.asObservable();

  private dataSubject = new BehaviorSubject<any>(null);
  data$ = this.dataSubject.asObservable();

  apiURL = environment.apiURL;
  private subject = new Subject<any>();

  constructor(private http: HttpClient) { }

  getAds() {
    return this.http.get(this.apiURL + 'ads');
  }

  getAdByCode(adCode: string): Observable<any> {
    return this.http.get(this.apiURL + 'ads/by-code/' + adCode);
  }

  setAdShow(value: boolean){
    this.adShowSource.next(value);
  }

  setData(data: any) {
    this.dataSubject.next(data);
  }

  getData() {
    return this.dataSubject.asObservable();
  }

  // sendClickCall(message: string) {
  //   this.subject.next({ text: message });
  // }

  // getClickCall(): Observable<any> {
  //   return this.subject.asObservable();
  // }
}
