export class PaymentModel {
  refIdPublicationGroup: string;
  totalAmount: number;
  orderStatus: string;
  refCouponId: string;
  downloadCount: number;
  firstName: string;
  lastName: string;
  email: string;
  companyName: string;
  phone: string;
  address: string;
  Country: string;
  State: string;
  ZipCode: string;
  CouponCode: string;
  PaymentMethod: string;
  CardNo: string;
  expirationcard: string;
  expirationyear: string;
  CardSecurityNo: string;
  collectionId:string;
}
