import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PublicationGroupService {

  apiURL = environment.apiURL;
  constructor(private http: HttpClient) { }

  getPublicationGroups(){
    return this.http.get(this.apiURL+'publication-groups');
  }

  getSinglePublicationGroup(id){
    return this.http.get(this.apiURL+'publication-groups/'+id);
  }

  getPublicationGroupMapping(refGroup, refPublication){
    return this.http.post(this.apiURL+'publication-groups/mapping', {refGroup: refGroup, refPublication: refPublication});
  }
}
