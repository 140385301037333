import { Injectable } from '@angular/core';
import { of, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PublicationService {

  apiURL = environment.apiURL;
  private subject = new Subject<any>();
  constructor(private http: HttpClient) { }

  getPublications(){
    return this.http.get(this.apiURL+'publications');
  }

  getSinglePublication(id){
    return this.http.get(this.apiURL+'publications/idpublication/'+id);
  }

  sendClickCall(message: string) {
    this.subject.next({ text: message });
  }

  getClickCall(): Observable<any> {
      return this.subject.asObservable();
  }
}
