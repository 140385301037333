import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-not-found",
  templateUrl: "./not-found.component.html",
  styleUrls: ["./not-found.component.css"],
})
export class NotFoundComponent implements OnInit {
  message: string;
  logoPath: string;
  primaryColor: string;
  enableHTML: boolean = false;
  constructor(private router: Router) {
    const navigation = this.router.getCurrentNavigation();
    console.log(navigation);
    if (!navigation.extras.replaceUrl) {
      const state = navigation.extras.state;
      this.message = state.data
        ? state.data
        : "Sorry the page you are looking for cannot be found. Please make sure you have provided the correct URL.";
      this.logoPath = state.logoPath
        ? state.logoPath
        : "/assets/iwrapper-logo.png";
      this.primaryColor = state.primaryColor ? state.primaryColor : "";
      this.enableHTML = state.enableHTML ? state.enableHTML : false;
    } else {
      this.message =
        "Sorry the page you are looking for cannot be found. Please make sure you have provided the correct URL.";
      this.logoPath = "/assets/iwrapper-logo.png";
      this.primaryColor = "";
      this.enableHTML = false;
    }
  }

  ngOnInit() {
    console.log("here");
  }
}
