import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import license from "../pdfviewer//license-key";
import * as UIExtension from "../../../foxit-lib/UIExtension.full.js";
import * as preloadJrWorker from "../../../foxit-lib/preload-jr-worker.js";
import { PaymentService } from "src/app/services/payment.service";
import { environment } from "../../../environments/environment";
import { RecaptchaComponent } from "ng-recaptcha";
import { DeviceDetectorService } from "ngx-device-detector";
import { PDFViewerComponent } from "src/app/components/pdfviewer/pdfviewer.component";
import { HttpClient } from "@angular/common/http";
declare var $: any;
@Component({
  providers: [PDFViewerComponent],
  selector: "app-direct-download",
  templateUrl: "./direct-download.component.html",
  styleUrls: ["./direct-download.component.css"],
})
export class DirectDownloadComponent implements OnInit {
  order_no: any;
  pdfui: any;
  userDataObj: any = {};
  captchaKey: any = environment.captchaSiteKey;
  captchaResponse: any;
  userName: String = "";
  lines: String;
  useremailAddress: string = "";
  noOfPages: number;
  setinterval: any;
  pdfUrl: string;
  uploadProgress: number = 0;
  organization: string;
  watermarkMsg: string;
  userInfo: any = {};
  publicationName: string;
  loader: Boolean = false;
  captchaHide: boolean = false;
  monetizepub: boolean = false;
  checkdownloadstatus = false;
  monthsNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  previousUrl: string;
  navigationMessage: NavigationExtras = {
    state: {
      enableHTML: false,
      logoPath: "",
      primaryColor: "",
      data: "Sorry the page you are looking for cannot be found. Please make sure you have provided the correct URL.",
    },
  };
  @ViewChild(RecaptchaComponent, { static: false })
  captchaElem!: RecaptchaComponent;

  constructor(
    private route: ActivatedRoute,
    private paymentService: PaymentService,
    private _router: Router,
    private deviceService: DeviceDetectorService,
    private pdfComp: PDFViewerComponent,
    private http: HttpClient
  ) {
    if (this._router.getCurrentNavigation().extras.hasOwnProperty("state")) {
      this.previousUrl =
        this._router.getCurrentNavigation().extras.state.previousurl;
      // console.log(this.previousUrl);
    } else {
      this._router.navigate(["/message"], this.navigationMessage);
    }
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.order_no = params.order_no ? params.order_no : "";
      if (sessionStorage.getItem("userData") != null) {
        this.userDataObj = JSON.parse(sessionStorage.getItem("userData"));
        // this.userDataObj = this.userDataObj.userData;
      }
      // console.log(this.userDataObj);
    });
  }
  wordwrap(str, len) {
    return (
      str.match(new RegExp("(\\S.{0," + (len - 1) + "})(?=\\s+|$)", "g")) || []
    ).join("\n");
  }
  async getPublication(orderNo) {
    // API call to get publication details
    if (orderNo != null && orderNo != undefined) {
      if (
        orderNo == "6fbe94394b4e0d111b4bca9f292d5a5d" ||
        orderNo == "65be24394b4e1d111b4bcccf292d555d"
      ) {
        if (orderNo == "65be24394b4e1d111b4bcccf292d555d") {
          console.log(this.userDataObj.userData);
          this.userInfo.fullName = atob(this.userDataObj.userData.fullName);

          this.userInfo.email = atob(this.userDataObj.userData.emailaddress);
          if (this.userDataObj.userData.organization) {
            this.userInfo.organization = atob(
              this.userDataObj.userData.organization
            );
            this.organization = atob(this.userDataObj.userData.organization);
          }
          if (this.userDataObj.userData.phoneno) {
            this.userInfo.phoneNumber = atob(this.userDataObj.userData.phoneno);
          }

          this.pdfComp.addUsageHistory(
            atob(this.userDataObj.userData.customerId),
            atob(this.userDataObj.userData.RefGroupId),
            atob(this.userDataObj.userData.idPublication),
            atob(this.userDataObj.userData.publicationTitle),
            atob(this.userDataObj.userData.refPublicationGroupId),
            "download",
            "allow",
            this.userInfo,
            false,
            ""
          );
          this.publicationName = atob(
            this.userDataObj.userData.publicationTitle
          );
          this.userName = atob(this.userDataObj.userData.fullName);

          this.pdfUrl = atob(this.userDataObj.userData.filePath);
          this.noOfPages = Number(atob(this.userDataObj.userData.noOfPages));
          this.uploadProgress = 2;
          this.useremailAddress = atob(this.userDataObj.userData.emailaddress);
          this.watermarkMsg =
            "This document is licensed to " +
            this.userName +
            " (" +
            this.useremailAddress +
            ") of " +
            this.organization +
            ".";
          if (this.watermarkMsg.length > 124) {
            this.watermarkMsg = this.wordwrap(this.watermarkMsg, 124);
          }
        } else {
          this.noOfPages = 1;
          this.monetizepub = true;
          this.pdfUrl = "/docs/demo/ACCESS_PAYMENT_PUBLICATION.pdf";
          this.publicationName = "iWrapper Demo Publication";
          this.watermarkMsg =
            "This publication is licensed to [Licensee Name]. Licensed on [Purchase Date]";
        }
        this.loader = true;
        this.preparedemoPDF();
      } else {
        this.monetizepub = true;

        await $.get("https://www.cloudflare.com/cdn-cgi/trace", (data) => {
          // Safely handle the data received
          this.lines = data ? data.split("\n") : [];
        });
          // Ensure `this.lines[2]` and `this.lines[5]` exist before proceeding
        const ipAddress = this.lines[2] ? this.lines[2].split("=")[1] : "";
        const browserInfo = this.lines[5] ? this.lines[5].split("=")[1] : "";
        this.paymentService
          .downloadPurchasedPublicationByUrl({
            orderNo: orderNo,
            ipAddress: ipAddress,
            browserInfo: browserInfo,
            captchaPayload: this.captchaResponse,
          })
          .subscribe((res: any) => {
            // console.log(res);
            this.uploadProgress = 2;
            this.preparePDF(res.publication);
          });
      }
    }
  }

  preparePDF(publication) {
    this.uploadProgress = 8;
    var stampString =
      "This publication is licensed to CUSTOMERNAME. Licensed on ORDERDATE";
    //console.log(this.userInfo);
    const isMobile = this.deviceService.isMobile();
    var readyWorker = preloadJrWorker({
      workerPath: "/foxit-lib/",
      enginePath: "/foxit-lib/jr-engine/gsdk",
      licenseSN: license.licenseSN,
      licenseKey: license.licenseKey,
    });
    var PDFUI = UIExtension.PDFUI;
    var Events = UIExtension.PDFViewCtrl.Events;
    var Log = UIExtension.PDFViewCtrl.Log;

    Log.setLogLevel(Log.LEVELS.LEVEL_ERROR);
    var selectionTool = {};
    var menuSelectionTool = {};
    var selectionTool = {};
    var menuSelectionTool = {};
    if (!isMobile) {
      if (!publication.permissions.allowCopy) {
        selectionTool = {
          target: "selection-text-annotation",
          action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
        };
        menuSelectionTool = {
          target: "fv--contextmenu-item-select-tool",
          action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
        };
      } else {
        selectionTool = {
          target: "selection-text-annotation",
          action: "",
        };
        menuSelectionTool = {
          target: "fv--contextmenu-item-select-tool",
          action: "",
        };
      }
    } else {
      selectionTool = {
        target: "fv--text-contextmenu",
        action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
      };
    }
    var printTool = ["/foxit-lib/uix-addons/thumbnail"];
    if (publication.permissions.allowedPrintCount == -1) {
      printTool.push("/foxit-lib/uix-addons/print");
      printTool.concat(
        UIExtension.PDFViewCtrl.DeviceInfo.isMobile
          ? []
          : "/foxit-lib/uix-addons/text-object"
      );
    }
    if (!isMobile) {
      var pdfui = new PDFUI({
        viewerOptions: {
          libPath: "/foxit-lib",
          jr: {
            readyWorker: readyWorker,
          },
        },
        renderTo: "#viewer",
        appearance: UIExtension.appearances.adaptive,
        Watermark_Flag: UIExtension.UIConsts.onTop,
        fragments: [
          selectionTool,
          menuSelectionTool,
          {
            target: "snapshot-button",
            action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
          },
          {
            target: "home-tab-group-magnifier",
            action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
          },
          {
            target: "open-file-button-list",
            action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
          },
          {
            target: "download-file-button",
            action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
          },
          {
            target: "comment-tab",
            action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
          },
          {
            target: "protect-tab",
            action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
          },
          {
            target: "edit-tab",
            action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
          },
          {
            target: "comment-list-sidebar-panel",
            action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
          },
          // {
          //    target: 'sidebar-thumbnail',
          //    action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE
          // },
          {
            target: "sidebar-layers",
            action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
          },
          {
            target: "home-tab-group-marquee",
            action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
          },
        ],
        addons: printTool,
      });
    } else {
      var pdfui = new PDFUI({
        viewerOptions: {
          libPath: "/foxit-lib",
          jr: {
            readyWorker: readyWorker,
          },
        },
        renderTo: "#viewer",
        appearance: UIExtension.appearances.adaptive,
        fragments: [
          selectionTool,
          /* dropDownIcon, */
          {
            target: "stamp-drop-down-ui",
            action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
          },
          {
            target: "fv--open-localfile-button",
            action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
          },
          {
            target: "fv--open-fromurl-button",
            action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
          },
          {
            target: "download-file-button",
            action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
          },
          {
            target: "fv-mobile-attachment-dropdown",
            action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
          },
          {
            target: "fv--mobile-header-main",
            action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
          },
          {
            target: "fv--mobile-toolbar-tabs",
            action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
          },
          {
            target: "fv--mobile-header-left",
            action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
          },
        ],
        addons: printTool,
      });
    }

    if (publication.isEncrypted) {
      // pdfui
      //   .openPDFByHttpRangeRequest(
      //     {
      //       range: {
      //         //url: '/enc-docs/'+publication.idCustomer+"/"+publication.idGroup+'/ENC_'+publication.idPublication+'.pdf'
      //         //url: '/assets/docs/dummy.pdf'
      //         url: publication.filePath,
      //         // url: "/assets/docs/dev-hvi/2018_SOA_REPORT.pdf",
      //       },
      //     },
      //     { fileName: publication.name, password: publication.openPassword }
      //   )
      this.setinterval = setInterval(() => {
        // console.log(this.uploadProgress);
        if (this.uploadProgress >= 18) {
          // this.uploadProgress = 60;
        } else {
          this.uploadProgress++;
        }
      }, 600);
      this.http
        .get(
          environment.storageURL +
            "/docs/" +
            publication.idCustomer +
            "/" +
            publication.idPublication +
            ".pdf",
          {
            responseType: "blob",
          }
        )
        .subscribe((resp: any) => {
          pdfui
            .openPDFByFile(resp, {
              password: publication.openPassword,
            })
            .then((resp) => {
              let result = 0;
              clearInterval(this.setinterval);
              var setintervalpdfdownload = setInterval(() => {
                // console.log(this.uploadProgress);
                if (this.uploadProgress >= 98) {
                  // this.uploadProgress = 60;
                } else {
                  this.uploadProgress++;
                }
              }, 1000);
              for (var i = 0; i < publication.noOfPages; i++) {
                resp.getPageByIndex(i).then((page) => {
                  // this.uploadProgress = this.uploadProgress + 0.1;
                  // if (this.uploadProgress >= 80) {
                  //   //  console.log(this.uploadProgress);
                  //   this.uploadProgress = 80;
                  // }
                  const data = {
                    type: "text",
                    text: stampString
                      .replace("CUSTOMERNAME", publication.customerName)
                      .replace(
                        "ORDERDATE",
                        `${
                          this.monthsNames[
                            new Date(publication.OrderDate).getMonth()
                          ]
                        } ${new Date(
                          publication.OrderDate
                        ).getDate()}, ${new Date(
                          publication.OrderDate
                        ).getFullYear()}`
                      ),

                    watermarkSettings: {
                      position: "BottomCenter",
                      offsetX: 0,
                      offsetY: 20,
                      scaleX: 1,
                      scaleY: 1,
                      rotation: 0,
                      opacity: 100,
                    },
                    watermarkTextProperties: {
                      font: 4,
                      fontSize: 9,
                      color: 0x787cff,
                      fontStyle: "normal",
                      lineSpace: 1,
                      alignment: "center",
                    },
                  };
                  page.addWatermark(data);
                  result += 1;
                  if (result == publication.noOfPages) {
                    downloadPDF(resp).then((d) => {
                      this.uploadProgress = 100;
                      clearInterval(setintervalpdfdownload);
                      this.loader = false;
                      this.navigationMessage.state.data = "directdownloadurl";
                      setTimeout(() => {
                        this._router.navigate(
                          [this.previousUrl],
                          this.navigationMessage
                        );
                      }, 1500);
                      // console.log("Downloaded");
                    });

                    this.captchaResponse = null;
                  }
                });
                // this.uploadProgress = this.uploadProgress + 0.1;
                // if (this.uploadProgress >= 80) {
                //   // console.log(this.uploadProgress);
                //   this.uploadProgress = 80;
                // }
              }
            });
        });
    }

    function downloadPDF(pdfDoc) {
      let bufferArray = [];
      return new Promise((resolve, reject) => {
        var length = 6;
        var charset =
          "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        let newOwnerPassword = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
          newOwnerPassword += charset.charAt(Math.floor(Math.random() * n));
        }
        var test = {
          ownerPassword: newOwnerPassword,
          permission: 0xfffff804,
          cipher: "aes256",
          isEncryptMetaData: true,
          expected: true,
          downloadName: "setPasswordAndPermission_modify_ownerPWabc.pdf",
        };
        var userPassword = "";
        pdfDoc.loadPDFForm().then((PDFForm) => {
          // console.log(PDFForm);
          pdfDoc
            .setPasswordAndPermission(
              "",
              test.ownerPassword,
              test.permission,
              test.cipher,
              test.isEncryptMetaData
            )
            .then((result) => {
              //  console.log(result);
              if (result) {
                let bufferArray = [];
                pdfDoc
                  .getStream(function ({ arrayBuffer, offset, size }) {
                    bufferArray.push(arrayBuffer);
                  })
                  .then(function (size) {
                    var blob = new Blob(bufferArray, {
                      type: "application/pdf",
                    });
                    const url = window.URL.createObjectURL(blob);
                    //window.open(url);
                    const anchor = document.createElement("a");
                    anchor.download = "purchasedPublication.pdf";
                    anchor.href = url;
                    resolve(anchor.click());
                  });
              }
            });
        });
      });
    }
  }
  preparedemoPDF() {
    this.uploadProgress = 1;
    //progreesbar = 5;
    var pubTitle = this.publicationName;
    var nopages = this.noOfPages;
    var pdfopenurl = this.pdfUrl;
    var stampMessage = this.watermarkMsg;
    const isMobile = this.deviceService.isMobile();
    var readyWorker = preloadJrWorker({
      workerPath: "/foxit-lib/",
      enginePath: "/foxit-lib/jr-engine/gsdk",
      licenseSN: license.licenseSN,
      licenseKey: license.licenseKey,
    });
    var PDFUI = UIExtension.PDFUI;
    var Events = UIExtension.PDFViewCtrl.Events;
    var Log = UIExtension.PDFViewCtrl.Log;

    Log.setLogLevel(Log.LEVELS.LEVEL_ERROR);
    var selectionTool = {};
    var menuSelectionTool = {};
    var selectionTool = {};
    var menuSelectionTool = {};
    if (!isMobile) {
      selectionTool = {
        target: "selection-text-annotation",
        action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
      };
      menuSelectionTool = {
        target: "fv--contextmenu-item-select-tool",
        action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
      };
    } else {
      selectionTool = {
        target: "fv--text-contextmenu",
        action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
      };
    }

    var printTool = ["/foxit-lib/uix-addons/thumbnail"];
    if (!isMobile) {
      var pdfui = new PDFUI({
        viewerOptions: {
          libPath: "/foxit-lib",
          jr: {
            readyWorker: readyWorker,
          },
        },
        renderTo: "#viewer",
        appearance: UIExtension.appearances.adaptive,
        Watermark_Flag: UIExtension.UIConsts.onTop,
        fragments: [
          selectionTool,
          menuSelectionTool,
          {
            target: "snapshot-button",
            action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
          },
          {
            target: "home-tab-group-magnifier",
            action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
          },
          {
            target: "open-file-button-list",
            action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
          },
          {
            target: "download-file-button",
            action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
          },
          {
            target: "comment-tab",
            action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
          },
          {
            target: "protect-tab",
            action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
          },
          {
            target: "edit-tab",
            action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
          },
          {
            target: "comment-list-sidebar-panel",
            action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
          },
          // {
          //    target: 'sidebar-thumbnail',
          //    action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE
          // },
          {
            target: "sidebar-layers",
            action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
          },
          {
            target: "home-tab-group-marquee",
            action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
          },
        ],
        addons: printTool,
      });
    } else {
      var pdfui = new PDFUI({
        viewerOptions: {
          libPath: "/foxit-lib",
          jr: {
            readyWorker: readyWorker,
          },
        },
        renderTo: "#viewer",
        appearance: UIExtension.appearances.adaptive,
        fragments: [
          selectionTool,
          /* dropDownIcon, */
          {
            target: "stamp-drop-down-ui",
            action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
          },
          {
            target: "fv--open-localfile-button",
            action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
          },
          {
            target: "fv--open-fromurl-button",
            action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
          },
          {
            target: "download-file-button",
            action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
          },
          {
            target: "fv-mobile-attachment-dropdown",
            action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
          },
          {
            target: "fv--mobile-header-main",
            action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
          },
          {
            target: "fv--mobile-toolbar-tabs",
            action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
          },
          {
            target: "fv--mobile-header-left",
            action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE,
          },
        ],
        addons: printTool,
      });
    }
    //  console.log("here");
    this.setinterval = setInterval(() => {
      // console.log(this.uploadProgress);
      if (this.uploadProgress >= 18) {
        // this.uploadProgress = 60;
      } else {
        this.uploadProgress++;
      }
    }, 300);
    //var setintervalpdf = (nopages / 100) * 1000;
    //console.log(setintervalpdf);
    this.http
      .get(
        environment.storageURL + pdfopenurl,

        {
          responseType: "blob",
        }
      )
      .subscribe((resp: any) => {
        pdfui.openPDFByFile(resp).then((resp) => {
          // pdfui
          //   .openPDFByHttpRangeRequest(
          //     {
          //       range: {
          //         //url: '/enc-docs/'+publication.idCustomer+"/"+publication.idGroup+'/ENC_'+publication.idPublication+'.pdf'
          //         //url: '/assets/docs/dummy.pdf'
          //         url: pdfopenurl,
          //         //url: "/assets/docs/dev-hvi/2018_SOA_REPORT.pdf",
          //       },
          //     },
          //     { fileName: "Payment Publication" }
          //   )
          //.then((resp) => {
          //console.log(resp.pagePromiseCache.length);
          //console.log();
          // this.uploadProgress = 10;
          let result = 0;
          clearInterval(this.setinterval);
          var setintervalpdfdownload = setInterval(() => {
            // console.log(this.uploadProgress);
            if (this.uploadProgress >= 98) {
              // this.uploadProgress = 60;
            } else {
              this.uploadProgress++;
            }
          }, 500);
          // console.log(setintervalpdf);
          for (var i = 0; i < nopages; i++) {
            resp.getPageByIndex(i).then((page) => {
              // this.uploadProgress = this.uploadProgress + 0.1;
              // if (this.uploadProgress >= 80) {
              //   //  console.log(this.uploadProgress);
              //   this.uploadProgress = 80;
              // }
              const data = {
                type: "text",
                text: stampMessage,

                watermarkSettings: {
                  position: "BottomCenter",
                  offsetX: 0,
                  offsetY: 20,
                  scaleX: 1,
                  scaleY: 1,
                  rotation: 0,
                  opacity: 100,
                },
                watermarkTextProperties: {
                  font: 4,
                  fontSize: 9,
                  color: 0x787cff,
                  fontStyle: "normal",
                  lineSpace: 1,
                  alignment: "center",
                },
              };
              //   console.log(data);
              page.addWatermark(data);
              result += 1;
              if (result == nopages) {
                downloadPDF(resp).then((d) => {
                  this.loader = false;
                  this.uploadProgress = 100;
                  clearInterval(setintervalpdfdownload);
                  this.navigationMessage.state.data = "directdownloadurl";
                  setTimeout(() => {
                    this._router.navigate(
                      [this.previousUrl],
                      this.navigationMessage
                    );
                  }, 1500);
                });
              }
            });
            //this.uploadProgress = this.uploadProgress + 0.1;
            // console.log(this.uploadProgress);
            // if (this.uploadProgress >= 80) {
            //   //    console.log(this.uploadProgress);
            //   this.uploadProgress = 80;
            // }
          }
        });
      });
    function downloadPDF(pdfDoc) {
      let bufferArray = [];
      return new Promise((resolve, reject) => {
        var length = 6;
        var charset =
          "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        let newOwnerPassword = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
          newOwnerPassword += charset.charAt(Math.floor(Math.random() * n));
        }
        var test = {
          ownerPassword: newOwnerPassword,
          permission: 0xfffff804,
          cipher: "aes256",
          isEncryptMetaData: true,
          expected: true,
          downloadName: "setPasswordAndPermission_modify_ownerPWabc.pdf",
        };
        var userPassword = "";
        pdfDoc.loadPDFForm().then((PDFForm) => {
          // console.log(PDFForm);
          pdfDoc
            .setPasswordAndPermission(
              "",
              test.ownerPassword,
              test.permission,
              test.cipher,
              test.isEncryptMetaData
            )
            .then((result) => {
              // console.log(result);
              if (result) {
                let bufferArray = [];
                pdfDoc
                  .getStream(function ({ arrayBuffer, offset, size }) {
                    bufferArray.push(arrayBuffer);
                  })
                  .then(function (size) {
                    var blob = new Blob(bufferArray, {
                      type: "application/pdf",
                    });
                    const url = window.URL.createObjectURL(blob);
                    //window.open(url);
                    const anchor = document.createElement("a");
                    anchor.download = pubTitle;
                    anchor.href = url;
                    resolve(anchor.click());
                  });
              }
            });
        });
      });
    }
  }

  resolved(captchaResponse: string) {
    var base64regex =
      /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
    // console.log(captchaResponse);
    this.captchaResponse = captchaResponse;
    if (this.captchaResponse) {
      debugger
      this.captchaHide = true;
      if (this.order_no == "65be24394b4e1d111b4bcccf292d555d") {
        if (sessionStorage.getItem("userData") != null) {
          this.userDataObj = JSON.parse(sessionStorage.getItem("userData"));
          if (this.userDataObj.userData.hasOwnProperty("fullName")) {
            if (
              base64regex.test(this.userDataObj.userData.fullName) &&
              base64regex.test(this.userDataObj.userData.emailaddress)
            ) {
              this.getPublication(this.order_no);
            } else {
              this.backHome();
            }
          } else {
            this.backHome();
          }
        } else {
          this.backHome();
        }
      } else {
        this.getPublication(this.order_no);
      }
    }
  }
  backHome() {
    this._router.navigate([this.previousUrl], this.navigationMessage);
  }
  // downloadFile() {
  //   if (this.order_no != "" && this.captchaResponse != null) {
  //     this.getPublication(this.order_no);
  //   }
  // }
}
