export class PublicationModel {
  buyUrl: string;
  filePath: string;
  idPublication: string;
  name: string;
  noOfPages: string;
  price: string;
  refCustomerId: string;
  uuid: string;
  _id: string;
}
