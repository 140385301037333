import {
  Component,
  OnInit,
  Inject,
  ElementRef,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { PaymentService } from "../../services/payment.service";
import { PaymentModel } from "../../models/paymentModel";
import { environment } from "../../../environments/environment";
import { DeviceDetectorService } from "ngx-device-detector";
@Component({
  selector: "app-payment-gateway-popup",
  templateUrl: "./payment-gateway-popup.component.html",
  styleUrls: ["./payment-gateway-popup.component.css"],
})
export class PaymentGatewayPopupComponent implements OnInit {
  paymentForm: FormGroup;
  isFormValid: boolean = true;
  isFormSubmitted: boolean = false;
  loader = false;
  requestModel: PaymentModel;
  publicationPrice: number = 0;
  couponMsg: string;
  cardPlacholder: string;
  couponStatus: string;
  showSection: string = "paymentForm";
  captchaKey: any = environment.captchaSiteKey;
  captchaResponse: any;
  monthName: number;
  errorMsg: string = "";
  // payment methods
  cardType: string = "allCards";
  supportedPaymentMethods: any;

  monthArr = {
    "01": "January",
    "02": "Februray",
    "03": "March",
    "04": "April",
    "05": "May",
    "06": "June",
    "07": "July",
    "08": "August",
    "09": "September",
    "10": "October",
    "11": "Novmeber",
    "12": "Decebmer",
  };

  regexArray: string[] = [
    "3[47]\\d{1,2}(| |-)\\d{6}\\1\\d{6}",
    "6(?:011|5\\d\\d)(| |-)(?:\\d{4}\\1){2}\\d{4}",
    "5[1-5]\\d{2}(| |-)(?:\\d{4}\\1){2}\\d{4}",
    "4[0-9]{12}(?:[0-9]{3})",
  ];

  securityCodeLengthRegex: string[] = [
    "[0-9]{4}",
    "[0-9]{3}",
    "[0-9]{3}",
    "[0-9]{3}",
  ];

  //selectedCardNoRegex: string = this.regexArray[0];
  //selectedSecurityNoRegex: string = this.securityCodeLengthRegex[0];
  // Current Year
  currentYear = new Date().getFullYear();
  yearArray: number[] = [];

  //countryselect: boolean;
  //disableSelect = new FormControl(false);
  constructor(
    private formBuilder: FormBuilder,
    private service: PaymentService,
    public dialogRef: MatDialogRef<PaymentGatewayPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public publication: any,
    private _snackBar: MatSnackBar,
    private changeRef: ChangeDetectorRef,
    private deviceService: DeviceDetectorService
  ) {}

  ngOnInit() {
    // this.supportedPaymentMethods = [
    //   { cardId: 1, cardName: "American Express" },
    //   { cardId: 2, cardName: "Discover Card" },
    //   { cardId: 3, cardName: "Master Card" },
    //   { cardId: 4, cardName: "Visa" },
    // ];
    const isMobile = this.deviceService.isMobile();
    if (isMobile) {
      this.cardPlacholder = "4111111111111111";
    } else {
      this.cardPlacholder = "Please enter 4111111111111111";
    }
    this.loadDefaultPaymentForm();
    this.initializeDefaults();
    //console.log(this.publication);
    this.publicationPrice = this.publication.publicationDetail.price;
    const date = new Date(); // 2009-11-10
    this.monthName = date.getMonth() + 1;
  }

  loadDefaultPaymentForm() {
    var cardNo, expirationcard, CardCCV, expirationyear;
    if (this.publication.idCollection == "BROWSER_ACCESS_PAYMENT") {
      cardNo = "4111111111111111";
      expirationcard = "01";
      CardCCV = "123";
      expirationyear = this.currentYear + 1;
      this.cardType = "Visa";
    } else {
      cardNo = null;
      expirationcard = null;
      CardCCV = null;
      expirationyear = null;
    }
    this.paymentForm = this.formBuilder.group({
      CouponCode: [null, Validators.maxLength(20)],
      // PaymentMethod: [paymetMethod, Validators.required],
      CardNo: [cardNo, [Validators.required, Validators.pattern("^[0-9]*$")]],
      expirationcard: [expirationcard, Validators.required],
      expirationyear: [expirationyear, Validators.required],
      CardSecurityNo: [CardCCV, [Validators.required, Validators.maxLength(4)]],
      CardFirstName: [null, [Validators.required, Validators.maxLength(20)]],
      CardLastName: [null, [Validators.required, Validators.maxLength(20)]],
      CardCustomerEmail: [
        null,
        [Validators.email, Validators.required, Validators.maxLength(30)],
      ],
      CardCustomerCompany: [
        null,
        [Validators.required, Validators.maxLength(30)],
      ],
      CardCustomerPhone: [null, Validators.maxLength(20)],
      CardCustomerAddress: [null, Validators.maxLength(50)],
      CustomerCountry: ["United States", Validators.required],
      CustomerState: ["Alabama", Validators.required],
      CardCustomerPostalCode: [null, Validators.maxLength(20)],
    });
  }
  initializeDefaults() {
    this.requestModel = new PaymentModel();
    this.publicationPrice = this.publication.publicationDetail.price;
    this.couponMsg = "";
    this.couponStatus = "0";
    this.populateYears();
  }

  populateYears() {
    for (let i = 0; i < 5; i++) {
      this.yearArray.push(this.currentYear);
      this.currentYear += 1;
    }
    this.currentYear = new Date().getFullYear();
  }

  onOptionsSelected(value) {
    if (value == "United States") {
      this.paymentForm.controls["CustomerState"].setValue("null");
      this.paymentForm.controls["CustomerState"].enable();
    } else {
      this.paymentForm.controls["CustomerState"].setValue(
        "Outside the U.S and Canada"
      );
      this.paymentForm.controls["CustomerState"].disable();
    }
  }

  validateCard(e) {
    if (this.paymentForm.controls.CardNo.value.length > 19) {
      e.preventDefault();
      this.paymentForm.controls.CardNo.setValue(
        this.paymentForm.controls.CardNo.value.substring(0, 19)
      );
    }
    if (this.paymentForm.controls.CardNo.value.length == 0) {
      this.cardType = "allCards";
    }

    var re = new RegExp("^6(?:011|5[0-9]{2})[0-9]{3,}$");
    var CardValidtionPass = true;
    if (this.paymentForm.controls.CardNo.value.match(re) != null) {
      this.paymentForm
        .get("CardSecurityNo")
        .setValidators([
          Validators.required,
          Validators.pattern(this.securityCodeLengthRegex[1]),
        ]);
      this.cardType = "Discover";
      CardValidtionPass = false;
    }
    var re = new RegExp("^3[47][0-9]{5,}$");
    if (this.paymentForm.controls.CardNo.value.match(re) != null) {
      this.cardType = "American Express";
      this.paymentForm
        .get("CardSecurityNo")
        .setValidators([
          Validators.required,
          Validators.pattern(this.securityCodeLengthRegex[0]),
        ]);
      CardValidtionPass = false;
    }
    var re = new RegExp(
      "^5[1-5][0-9]{5,}|222[1-9][0-9]{3,}|22[3-9][0-9]{4,}|2[3-6][0-9]{5,}|27[01][0-9]{4,}|2720[0-9]{3,}$"
    );
    if (this.paymentForm.controls.CardNo.value.match(re) != null) {
      this.paymentForm
        .get("CardSecurityNo")
        .setValidators([
          Validators.required,
          Validators.pattern(this.securityCodeLengthRegex[1]),
        ]);
      this.cardType = "MasterCard";
      CardValidtionPass = false;
    }
    var re = new RegExp("^4[0-9]{6,}$");
    if (this.paymentForm.controls.CardNo.value.match(re) != null) {
      this.paymentForm
        .get("CardSecurityNo")
        .setValidators([
          Validators.required,
          Validators.pattern(this.securityCodeLengthRegex[1]),
        ]);
      this.cardType = "Visa";
      CardValidtionPass = false;
    }
    // if(!CardValidtionPass){
    //   this.paymentForm.get('CardSecurityNo').setValidators([
    //     Validators.required,Validators.pattern(this.securityCodeLengthRegex[1])]);
    // }
  }

  validatePaymentForm() {
    //
    if (
      this.paymentForm.controls.CardNo.value != "4111111111111111" &&
      this.publication.idCollection == "BROWSER_ACCESS_PAYMENT"
    ) {
      this.paymentForm.controls["CardNo"].setErrors({});
      return (this.isFormValid = false);
    }

    if (this.paymentForm.controls.CardNo.invalid) {
      this.paymentForm.controls["CardNo"].setErrors({});
      return (this.isFormValid = false);
    }
    if (this.paymentForm.controls.expirationcard.invalid) {
      this.paymentForm.controls["expirationcard"].setErrors({});
      return (this.isFormValid = false);
    }

    if (this.paymentForm.controls.expirationyear.value == this.currentYear) {
      if (this.monthName > this.paymentForm.controls.expirationcard.value) {
        this.paymentForm.controls["expirationcard"].setErrors({});
        this.errorMsg = "Please select a valid expiry month.";
        return (this.isFormValid = false);
      }
    }
    if (this.paymentForm.controls.expirationyear.invalid) {
      console.log(this.paymentForm.controls.expirationyear);
      return (this.isFormValid = false);
    }
    if (this.paymentForm.controls.CardSecurityNo.invalid) {
      console.log(this.paymentForm.controls.CardSecurityNo);
      this.paymentForm.controls["CardSecurityNo"].setErrors({});
      return (this.isFormValid = false);
    }
    if (this.paymentForm.controls.CardFirstName.invalid) {
      console.log(this.paymentForm.controls.CardFirstName);
      this.paymentForm.controls["CardFirstName"].setErrors({});
      return (this.isFormValid = false);
    }
    if (this.paymentForm.controls.CardLastName.invalid) {
      console.log(this.paymentForm.controls.CardLastName);
      this.paymentForm.controls["CardLastName"].setErrors({});
      return (this.isFormValid = false);
    }
    if (this.paymentForm.controls.CardCustomerEmail.invalid) {
      console.log(this.paymentForm.controls.CardCustomerEmail);
      this.paymentForm.controls["CardCustomerEmail"].setErrors({});
      return (this.isFormValid = false);
    }
    if (this.paymentForm.controls.CustomerState.invalid) {
      console.log(this.paymentForm.controls.CustomerState);
      this.paymentForm.controls["CustomerState"].setErrors({});
      return (this.isFormValid = false);
    }
    return (this.isFormValid = true);
  }

  onpaymentFormSubmit() {
    //alert();
    if (this.validatePaymentForm()) {
      console.log(this.paymentForm);
      this.isFormSubmitted = true;
      this.loader = true;
      this.buildOrder(this.paymentForm.controls);
      //console.log(this.requestModel);
      // if(this.paymentForm.controls.CardNo.value=="4111111111111111" &&  this.publication.idCollection=="BROWSER_ACCESS_PAYMENT"){
      //   this.closePaymentModal();
      // }
      // else{
      this.service.postOrderDetail(this.requestModel).subscribe(
        (res: any) => {
          // console.log(res);
          if (res && res.success) {
            this.closePaymentModal();
            //alert("Transaction Successfull");
            this.loader = false;

            this._snackBar.open(
              "Your payment has been successfully processed. Please check your email for order details.",
              "",
              {
                duration: 3000,
                horizontalPosition: "center",
                verticalPosition: "bottom",
              }
            );
          } else {
            this.loader = true;
            this._snackBar.open(res.message, "OK", {
              duration: 5000,
              //horizontalPosition: "start",
              //verticalPosition: "top",
            });
          }
        },
        (err) => {
          this._snackBar.open(err.error.message, "OK", {
            duration: 5000,
            //horizontalPosition: "start",
            //verticalPosition: "top",
          });
        }
      );
      // }
    } else {
      console.log("here");
      this.paymentForm.markAllAsTouched();
    }
  }

  buildOrder(paymentForm) {
    //console.log(paymentForm);
    this.requestModel.Country = paymentForm.CustomerCountry.value;
    this.requestModel.State = paymentForm.CustomerState.value;
    this.requestModel.ZipCode = paymentForm.CardCustomerPostalCode.value;
    this.requestModel.address = paymentForm.CardCustomerAddress.value;
    this.requestModel.companyName = paymentForm.CardCustomerCompany.value;
    this.requestModel.email = paymentForm.CardCustomerEmail.value;
    this.requestModel.firstName = paymentForm.CardFirstName.value;
    this.requestModel.lastName = paymentForm.CardLastName.value;
    this.requestModel.phone = paymentForm.CardCustomerPhone.value;
    this.requestModel.refCouponId = paymentForm.CouponCode.value;
    this.requestModel.refIdPublicationGroup =
      this.publication.publicationGroupId;
    this.requestModel.collectionId = this.publication.idCollection;
    this.requestModel.totalAmount = this.publicationPrice;
    this.requestModel.CouponCode = paymentForm.CouponCode.value;
    this.requestModel.PaymentMethod = this.cardType;
    this.requestModel.CardNo = paymentForm.CardNo.value;
    this.requestModel.expirationcard = paymentForm.expirationcard.value;
    this.requestModel.expirationyear = paymentForm.expirationyear.value;
    this.requestModel.CardSecurityNo = paymentForm.CardSecurityNo.value;
  }

  applyCoupon() {
    if (this.paymentForm.controls.CouponCode.value.trim().length == 0) {
      //console.log("No Coupon Code");
      return;
    }
    this.service
      .validateCoupon(
        this.paymentForm.controls.CouponCode.value,
        this.publication.publicationDetail.idPublication
      )
      .subscribe(
        (res: any) => {
          //console.log(res);
          if (res.discountedPrice) {
            this.publicationPrice = res.discountedPrice;
            this.couponMsg = res.message;
            this.couponStatus = "1";
          }
        },
        (err: any) => {
          console.log(err.error.message);
          this.couponMsg = err.error.message;
          this.couponStatus = "-1";
        }
      );
  }

  closePaymentModal() {
    // if(this.paymentForm.controls.CardNo.value=="4111111111111111" &&  this.publication.idCollection=="BROWSER_ACCESS_PAYMENT"){
    //     var demoStatus = false;
    // }
    this.dialogRef.close({});
  }
  closeDialog() {
    this.dialogRef.close({});
  }
  previewForm() {
    if (!this.validatePaymentForm()) {
      this.paymentForm.markAllAsTouched();
      this._snackBar.open(
        "Please provide the required payment and billing information before proceeding.",
        "",
        {
          duration: 5000,
          horizontalPosition: "center",
          verticalPosition: "bottom",
        }
      );
    } else if (!this.captchaResponse) {
      this._snackBar.open("Please verify you're not a robot", "OK", {
        duration: 5000,
        horizontalPosition: "center",
        verticalPosition: "bottom",
      });
    } else {
      this.showSection = "previewForm";
    }
  }

  resolved(captchaResponse: string) {
    if (captchaResponse == null) {
      this.captchaResponse = null;
    }
    this.captchaResponse = captchaResponse;
  }
}
