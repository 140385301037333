import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-powered-by-iwrapper",
  templateUrl: "./powered-by-iwrapper.component.html",
  styleUrls: ["./powered-by-iwrapper.component.css"],
})
export class PoweredByIwrapperComponent implements OnInit {
  currentYear: any;
  constructor() {}

  ngOnInit() {
    this.currentYear = new Date().getFullYear();
  }
}
