import { Injectable } from '@angular/core';
import {HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GroupService {
  apiURL = environment.apiURL;
  constructor(private http: HttpClient) { }

  getGroups(){
    return this.http.get(this.apiURL+'groups');
  }

  getSingleGroup(id){
    return this.http.get(this.apiURL+'groups/idgroup/'+id);
  }
}
