import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  Inject,
  ViewChildren,
} from "@angular/core";
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { Router, NavigationExtras } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { PaymentService } from "../../services/payment.service";
import { environment } from "../../../environments/environment";

import license from "../pdfviewer//license-key";
import * as UIExtension from "../../../foxit-lib/UIExtension.full.js";
import * as preloadJrWorker from "../../../foxit-lib/preload-jr-worker.js";
import { RecaptchaComponent } from "ng-recaptcha";

declare var $: any;

@Component({
  selector: "app-download-pdf",
  templateUrl: "./download-pdf.component.html",
  styleUrls: ["./download-pdf.component.css"],
})
export class DownloadPdfComponent implements OnInit {
  downloadpdfForm: FormGroup;
  captchaKey: any = environment.captchaSiteKey;
  captchaResponse: any;
  navigationMessage: NavigationExtras = {
    state: {
      enableHTML: false,
      logoPath: "",
      primaryColor: "",
      data: {
        userData: {},
      },
    },
  };
  @ViewChild(RecaptchaComponent, { static: false })
  captchaElem!: RecaptchaComponent;

  pdfSrc: any;
  constructor(
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private service: PaymentService,
    private route: Router,
    public dialogRef: MatDialogRef<DownloadPdfComponent>,
    @Inject(MAT_DIALOG_DATA) public publication: any
  ) {}

  ngOnInit() {
    this.loadFormFields();
  }

  loadFormFields() {
    this.downloadpdfForm = this.formBuilder.group({
      OrderNo: [null, [Validators.maxLength(20), Validators.required]],
      EmailAddress: [
        null,
        [
          Validators.maxLength(40),
          Validators.required,
          Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$"),
        ],
      ],
    });
  }

  downloadOrder() {
    if (
      this.downloadpdfForm.controls.OrderNo.value == null ||
      this.downloadpdfForm.controls.OrderNo.value.trim().length == 0
    ) {
      this.downloadpdfForm.controls.OrderNo.setValue("");
      this._snackBar.open("Please provide order no", "OK", {
        duration: 4000,
        //horizontalPosition: "start",
        //verticalPosition: "top",
      });
      return;
    }

    if (
      this.downloadpdfForm.controls.EmailAddress.value == null ||
      this.downloadpdfForm.controls.EmailAddress.value.trim().length == 0
    ) {
      this.downloadpdfForm.controls.EmailAddress.setValue("");
      this._snackBar.open("Please provide email address", "OK", {
        duration: 4000,
        //horizontalPosition: "start",
        //verticalPosition: "top",
      });
      return;
    }
    if (
      this.publication.idCollection == "BROWSER_ACCESS_PAYMENT" &&
      this.downloadpdfForm.controls.OrderNo.value == "12345678"
    ) {
      this.closeDialog();
      this.navigationMessage.state.data.userData.RefGroupId =
        this.publication.idCollection;
      this.navigationMessage.state.data.userData.idPublication =
        this.publication.idPublication;
      console.log(this.route.url);
      //this.route.navigate(["/download/6fbe94394b4e0d111b4bca9f292d5a5d"]);
      this.route.navigateByUrl("/download/6fbe94394b4e0d111b4bca9f292d5a5d", {
        state: { previousurl: this.route.url },
      });
      //this.preparePDF();
    } else {
      this.service
        .validateOrderNo({
          orderNo: this.downloadpdfForm.controls.OrderNo.value.trim(),
          emailAddress: this.downloadpdfForm.controls.EmailAddress.value.trim(),
        })
        .subscribe(
          (resp: any) => {
            this.closeDialog();
            // this.navigationMessage.state.data.userData.previousurl =
            //   this.route.url;
            // //this.route.navigate([]);
            this.route.navigateByUrl("/download/" + resp.orderNo, {
              state: { previousurl: this.route.url },
            });
          },
          (err) => {
            this._snackBar.open(err.error.message, "OK", {
              duration: 4000,
            });
          }
        );
    }
  }

  closeDialog() {
    this.dialogRef.close({});
  }
  resolved(captchaResponse: string) {
    this.captchaResponse = captchaResponse;
  }
}
