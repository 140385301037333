(function (root, factory) {
  if (typeof exports === "object" && typeof module === "object") {
    module.exports = factory();
  } else if (typeof define === "function" && define.amd) {
    define([], factory);
  } else {
    var a = factory();
    for (var i in a) (typeof exports === "object" ? exports : root)[i] = a[i];
  }
})(self, function () {
  return {
    licenseSN: "NxNBKaqHS5dT3+8BDPlYMBCL2Y6OCgr5gFKWWINqPSx1EfnIXf2M5w==",
    licenseKey:
      "PjzsmLJVMV6sbxu+YOOVvunQ3N/2nBRsoYg/HO6NZ1JrjMvAaUAfFO0v55lot7vy9k1hAFANLy/CKVM9EOCbVPPnwDjNiP1sJI9i64z6YmhX7/XXD6sAetzalPPk1zarw47rLwN90xBJoPCBmO4/62WwqsQsFCKOVtv2mB9p7nfL6Nk/HEJqPDIBcHKYSu0NVeuwvZAoEO7r/tQky/xJyNycD7JDGbovVtY5OZCnsY0u3jifbJDQq0FRaHd2ivSKGm7QmBLhb8w57Y1A7uPa7yhP/Eh/0/m0GXMSoI8+W5q/wK9+75jWImyW2BJyC4cH2O9fdf+0Zb6TyGttNGW91Ql63LylejtexOi5BYilmfngRleEEWOPXTzIPOv163ngWC+nI7cIpL9IRLn25EP8QGkhk7Q7xF5jokOATtYflDLAR7JI836NfaquzwJU6CSGBw8VABaefEUe6C3WqBr9QfgWTzELe09jcJJm47oplRKwX0fwqEbo4DZvqZz7d6X6BXt+yJy4XHwoAdFHb++bHYnglUcPpPBBrU2nLFFIjVIwI+hT2Z4QeE+3XuKV8KaN/6hB+lE6a10mMMyH2Im9Z73GbhfVpoPPmOlfKEgWhUTKyUIkOwHUYE5HY+Zj87zdJIYlVHDdbf8cVPtS/u0xGA+gRTHkCNFtdoUHuG/MdAGwV4GP5df0nkCz7iwE7p3PsU7P2QcytXNqrqRIWuGbE3yRU70LGOUdG0hnw3S6LjU2EdP6tzLYBWxky8fYy87wcXzrPYPMK84zylwTIlcv5yjW0Wr39bxHqmFYnZUTHtHqGwCGBuh3VfIsHX5ggjMszvQpnj8n7pTSUVl6CNmjQkYQd7TAl4tmm7yQNR2UefUb6466tuNa/fRs7qN92T7apIjC80nBau+AXbbDvGbAmdjw0fHaR38lyG/Xyju9U8qgZj3/oH7O4p3PW+Q6i+WkDnFiiWbnkwkhSW93BowbBYeCXG0sGpvB/3VvNAxs3ySjZCiP060srLjdkmIyWbZh0Gl3U4NOYYYa6KtIr/ey2hy+Iq/E539xlXtEAzaI9MwhhYGAJ3kIy6agtIMzzGECiyU5rqWAhsA8dQaphtVdqJn/JBa26RM3yEjohvlQbH8dyDIPe3ZKfx42vsyveADoRb/b+7YuBw4xy7xdKlG+2LuIyLJ6l1K2iJI9B/w/h7sakuis7rDktiF6NIiehJlqPIu74WOlfE8JtCGZ146uZ4kDXkOPNLIt3ndMuiF+bNLvlDofThO1IvCEilwwoacD+TM=",
  };
});
