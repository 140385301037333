import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class AskForUserInfoService {
  apiURL = environment.apiURL;
  constructor(private http: HttpClient) {}
  UserInfoData(data) {
    return this.http.post(this.apiURL + "askforuserinfo", data);
  }

  updateSessionEndTime(uuid: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const currentTime = new Date().toISOString(); // Get the current time
    const requestBody = { uuid, sessionEndTime: currentTime }; // Create a request body object
    return this.http.post(`${this.apiURL}session`, requestBody, { headers });
  }

}
