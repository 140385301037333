import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { PDFViewerComponent } from './components/pdfviewer/pdfviewer.component';
import { DirectDownloadComponent } from './components/direct-download/direct-download.component';

const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'home/:customer_code/:collection_code/:publication_code', component: HomeComponent },
  { path: 'download/:order_no', component : DirectDownloadComponent},
  { path: 'publication', component: PDFViewerComponent },
  { path: ':customer_code/:collection_code/:publication_code', component: HomeComponent },
  { path: ':collection_code/:publication_code', component: HomeComponent },
  { path: 'message', component: NotFoundComponent },
  { path: 'forbidden', component: ForbiddenComponent },
  { path: '**', redirectTo: 'home'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
