import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class CustomerService {
  apiURL = environment.apiURL;
  constructor(private http: HttpClient) {}

  getCustomers() {
    return this.http.get(this.apiURL + "customers");
  }

  getSingleCustomer(id) {
    return this.http.get(this.apiURL + "customers/idcustomer/" + id);
  }
  getPublicationDetail(data: any) {
    return this.http.post(this.apiURL + "publicationpdfviewer", data);
  }
  PublicationPdfViewer(data: any) {
    return this.http.post(
      this.apiURL + "publicationpdfviewer/openpdfviewer",
      data
    );
  }
  PublicationOpenPassword(data: any) {
    return this.http.post(
      this.apiURL + "publicationpdfviewer/_guid",
      data
    );
  }
}
