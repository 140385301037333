import { Component, OnInit } from "@angular/core";
import { AdService } from "src/app/services/ad.service";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from "@angular/material/dialog";
import { MatBottomSheetRef } from "@angular/material/bottom-sheet";
import { environment } from "../../../environments/environment";
import { log } from "util";
declare var $: any;
@Component({
  selector: "app-gdpr-consent",
  templateUrl: "./gdpr-consent.component.html",
  styleUrls: ["./gdpr-consent.component.css"],
})
export class GdprConsentComponent implements OnInit {
  GDPRConsent: boolean = false;
  sharedData: any;
  customerId: string;
  

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<GdprConsentComponent>, 
    private adService: AdService, 
  ) {}

  ngOnInit() {

    this.adService.data$.subscribe(data => {
      this.sharedData = data;
      this.customerId = this.sharedData.idCustomer;
    });
    
  }
  rejectAll() {
    this.GDPRConsent = true;
  }
  acceptAll() {
    this._bottomSheetRef.dismiss(true);
    this.adService.setAdShow(true);
  }
  refererlink() {
    if (environment.fileReferrer) {
      window.open(environment.fileReferrer, "_self");
    } else {
      window.open(
        "https://www.iwrapper.com/terms-and-condition/?cookiepolicy",
        "_self"
      );
    }
  }
}
