import {
  Component,
  OnInit,
  Inject,
  Renderer2,
  ElementRef,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import {
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA,
} from "@angular/material/bottom-sheet";
import { DOCUMENT } from "@angular/common";
declare var $: any;
import { environment } from "../../../environments/environment";
import { NoSanitizePipe } from "./NoSanitizePipe";
@Component({
  selector: "app-show-ads",
  templateUrl: "./show-ads.component.html",
  styleUrls: ["./show-ads.component.css"],
  providers: [NoSanitizePipe],
})
export class ShowAdsComponent implements OnInit {
  loader: boolean = true;
  baseURL = environment.baseapiURL;
  sanitizeurl: any;
  //@ViewChild("adhtmlsnippet") container;
  @ViewChild("adhtmlsnippet", { static: false }) container: ElementRef;
  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private _bottomSheetRef: MatBottomSheetRef<ShowAdsComponent>,
    @Inject(DOCUMENT) private document: any,
    private _renderer2: Renderer2,
    private elementRef: ElementRef,
    private _nosanitizePipe: NoSanitizePipe,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.sanitizeurl = this._nosanitizePipe.transform(
      this.data.groupDetail.adSnippetCode
    );
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.loader = false;
      this.cd.detectChanges();
    }, 445);

    let scripts = this.container.nativeElement.getElementsByTagName("script");
    console.log(scripts);
    if (scripts.length > 0) {
      eval(scripts[0].text);
    }

    // const script = this.document.createElement("script");
    // script.type = "text/javascript";
    // const __this = this; //to store the current instance to call
    // script.async = true;
    // //script.id = "scr-637463160134482579-60019fd3ba2e541060a82288";
    // script.id = "scr-636994192822380419-5d2cbe26ba2e540b44fb8caf";
    // script.src =
    //   "https://cdn.uprivaladserver.net/scriptsv5/uphs-script.js?v=2.8.42";
    // script.setAttribute("data-width", "100%");
    // script.setAttribute("data-height", "auto");
    // this.elementRef.nativeElement
    //   .querySelector(".contentGDPR")
    //   .appendChild(script);
    // script.setAttribute(
    //   "data-url",
    //   "https://api.uprivaladserver.net/v2/a/show/?tid=5cf54327ba2e541614a5d73a&pid=60019fd3ba2e541060a82288&rnd=[CACHE-BUSTING-ID-HERE]"
    // );

    // script.setAttribute(
    //   "data-url",
    //   "https://api.uprivaladserver.net/v2/a/show/?tid=5cf54327ba2e541614a5d73a&pid=5d2cbe26ba2e540b44fb8caf&rnd=[CACHE-BUSTING-ID-HERE]"
    // );
    // script.onload = () => {
    //   // window["UpASHS"]().init(
    //   //   "637463160134482579-60019fd3ba2e541060a82288",
    //   //   "60019fd3ba2e541060a82288"
    //   // );
    //   // window["UpASHS"]().init(
    //   //   "636994192822380419-5d2cbe26ba2e540b44fb8caf",
    //   //   "5d2cbe26ba2e540b44fb8caf"
    //   // );
    //   setTimeout(() => {
    //     const iframe = this.elementRef.nativeElement.querySelector("iframe");
    //     const iframebody = this.elementRef.nativeElement
    //       .querySelector("iframe")
    //       .contentWindow.document.querySelector("body");
    //     const width = this.elementRef.nativeElement
    //       .querySelector("iframe")
    //       .contentWindow.document.querySelector("img")
    //       .getAttribute("width");
    //     const height = this.elementRef.nativeElement
    //       .querySelector("iframe")
    //       .contentWindow.document.querySelector("img")
    //       .getAttribute("height");
    //     iframebody.style.cssText += "text-align:center";
    //     console.log(width);
    //     iframe.style.cssText += "width:" + width + "px;height:" + height + "px";
    //     this.loader = false;
    //   }, 800);
    //   //this.loader = false;
    // };
  }

  closeDialog() {
    //this._bottomSheetRef.dismiss(true);
    this._bottomSheetRef.dismiss(true);
  }
}
